import { APIFilter } from '@/utils/api'

export default {
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcita', id)
    const resp = await Vue.$api.call('cita.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
