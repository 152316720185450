<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="titulo"
      :rules="formRules.titulo"
    >
      <v-text-field
        v-model="form.titulo"
        clearable
        flat
        label="Título"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="fcita"
      :rules="formRules.fcita"
    >
      <b10-date-time-picker
        v-model="form.fcita"
        title="Fecha de inicio"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="ffin"
      :rules="formRules.ffin"
    >
      <b10-date-time-picker
        v-model="form.ffin"
        title="Fecha de fin"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.cita"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './CitaFormData'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    fcita: {
      type: Date,
      default: function () { return new Date() },
    },
  },
  data () {
    return {
      form: {
        fcita: this.fcita,
        ffin: null,
        titulo: null,
        cita: null,
      },
      formRules: {
        fcita: { required: true },
        titulo: { required: true },
        ffin: { fechaFinValida: true },
      },
    }
  },
  async created () {
    extend('fechaFinValida', {
      message: 'La fecha de fin debe ser posterior a la de inicio.',
      validate: async (value) => {
        if (value && value < this.form.fcita) {
          return false
        } else {
          return true
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
  },
}
</script>
