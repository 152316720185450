import { CITA } from '@/utils/consts'

export default {
  async insert (Vue, identificador, idempleado, formData) {
    const resp = await Vue.$api.call(
      'cita.insert',
      {
        values: {
          titulo: formData.titulo,
          cita: formData.cita,
          fcita: formData.fcita,
          ffin: formData.ffin,
          identificador: identificador,
          estado: CITA.estado.pendiente,
          idempleado: idempleado,
          tipo: !!identificador ? CITA.tipo.crm : CITA.tipo.otros
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
